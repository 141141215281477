import _ from 'lodash';
import React from 'react';

import AppFooter from '../AppFooter';
import Button from '../Button';

const Waiting = (props) => {
  const players = _.map(props.players, (player, index) => (
    <h3
      key={ index }
      className="player-name"
    >
      { `${index + 1}. ${props.playerNames[player]}` }
    </h3>
  ));

  return (
    <div className="waiting">
      <h1 className="title">{ `${props.roomCode}` }</h1>
      <h2 className="player-label">{ 'Players' }</h2>
      <div className="player-list">
        { players }
      </div>
      { props.showStartButton && <Button onClick={ props.startGame }>{ 'Start Game' }</Button> }
      <Button onClick={ props.leaveGame }>{ 'Leave Game' }</Button>
      <AppFooter />
    </div>
  );
};

export default Waiting;
