import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCiNs2WkFli9CCN5FFq5qQUdLLuAuMqDR0",
  authDomain: "rummy-b7d76.firebaseapp.com",
  databaseURL: "https://rummy-b7d76.firebaseio.com",
  projectId: "rummy-b7d76",
  storageBucket: "rummy-b7d76.appspot.com",
  messagingSenderId: "977186826801",
  appId: "1:977186826801:web:fa547d83f74ead205c4310"
};

class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);

    this.firestore = firebase.firestore();

    if (window.location.hostname === 'localhost') {
      firebase.functions().useFunctionsEmulator('http://localhost:5001');
      this.firestore.settings({
        host: 'localhost:8080',
        ssl: false
      });
    }

    this.users = this.firestore.collection('users');

    this.auth = firebase.auth();
  }

  async signUp(email, password) {
    return new Promise((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async signIn(email, password) {
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async logout() {
    return new Promise((resolve, reject) => {
      firebase.auth().signOut()
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async getCurrentGame(uid) {
    return new Promise((resolve, reject) => {
      this.users.doc(uid).get()
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async joinGame(roomCode, name) {
    const joinFunc = firebase.functions().httpsCallable('joinGame');

    return new Promise((resolve, reject) => {
      joinFunc({ roomCode, name })
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async drawCard(fromDeck) {
    const drawFunc = firebase.functions().httpsCallable('drawCard');

    return new Promise((resolve, reject) => {
      drawFunc({ fromDeck })
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async playCard(pileIndex, cardIndex, card) {
    const playFunc = firebase.functions().httpsCallable('playCard');

    return new Promise((resolve, reject) => {
      playFunc({ pileIndex, cardIndex, card })
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async goDown(bunches) {
    const downFunc = firebase.functions().httpsCallable('goDown');

    return new Promise((resolve, reject) => {
      downFunc({ bunches })
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async discardCard(card) {
    const discardFunc = firebase.functions().httpsCallable('discardCard');

    return new Promise((resolve, reject) => {
      discardFunc({ card })
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async createGame(name) {
    const createFunc = firebase.functions().httpsCallable('createGame');

    return new Promise((resolve, reject) => {
      createFunc({ name })
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async startGame() {
    const startFunc = firebase.functions().httpsCallable('startGame');

    return new Promise((resolve, reject) => {
      startFunc()
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  async leaveGame() {
    const leaveFunc = firebase.functions().httpsCallable('leaveGame');

    return new Promise((resolve, reject) => {
      leaveFunc()
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }

  // TODO: use actual game
  async resetGame({ game = 'abcd' } = {}) {
    const resetFunc = firebase.functions().httpsCallable('resetGame');

    return new Promise((resolve, reject) => {
      resetFunc({ game: 'abcd' })
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  }
}



export default Firebase;
