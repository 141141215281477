import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import PlayingCard from '../PlayingCard';

const YourHand = (props) => {
  const highlightedHand = props.hasDrawn && props.isYourTurn;

  const sortFunction = props.sortBySet ?
    ((a, b) => (a-1) % 13 - (b-1) % 13) :
    ((a, b) => a-b);

  const cardList = [...props.cards].sort(sortFunction)

  const cards = _.map(cardList, (card, index) => {
    if (_.indexOf(props.onHold, index) !== -1) {
      return null;
    }

    const isPickedCard = props.pickedCardValue === card && _.indexOf(cardList, card) === index;

    const clickable = highlightedHand && (
      props.pickedCardValue === -1 ||
      isPickedCard
    );

    return (
      <PlayingCard
        value={ card }
        key={ index }
        highlighted={ clickable }
        zoomed={ isPickedCard }
        onClick={ clickable && (() => props.clickHand(index, card)) }
      />
    );
  });

  return (
    <div className="your-hand">
      { cards }
    </div>
  );
}

YourHand.propTypes = {
  hasDrawn: PropTypes.bool,
  isYourTurn: PropTypes.bool,
  cards: PropTypes.array,
  onHold: PropTypes.array
}

export default YourHand;
