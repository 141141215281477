import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Input = (props) => {
  const inputClasses = classNames('input', {
    dark: props.onDark
  });

  const onChange = (event) => {
    if (event.target.value.length <= props.maxLength) {
      props.onChange(event.target.value);
    }
  }

  return (
    <label htmlFor={ props.id } className={ inputClasses }>
      <input
        id={ props.id }
        className="real-input"
        value={ props.value }
        onChange={ onChange }
        type={ props.type || 'text' }
        placeholder="&nbsp;"
      />
      <span className="label">{ props.label }</span>
      <span className="focus-bg"></span>
    </label>
  );
}

Input.propTypes = {
  onChange: PropTypes.func,

  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  onDark: PropTypes.bool
};

Input.defaultProps = {
  maxLength: 128
};

export default Input;
