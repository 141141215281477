import classNames from 'classnames';
import React from 'react';

const Button = (props) => (
  <button
    className={ classNames('button', props.className, { disabled: props.disabled }) }
    onClick={ props.onClick }
    disabled={ props.disabled }
  >
    { props.children }
  </button>
);

export default Button;
