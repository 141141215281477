import React from 'react';

import ScreenLoader from './ScreenLoader';

import InProgress from './GameContent/InProgress';
import Waiting from './GameContent/Waiting';

class LobbyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      game: null,
      isLoading: true,
    };

    this.startGame = this.startGame.bind(this);
  }

  updateGameData = (doc) => {
    this.setState({
      isLoading: false,
      game: doc.data()
    });
  }

  async startGame() {
    try {
      this.setState({ isLoading: true });
      await this.props.firebase.startGame();
    } catch (error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.props.firebase.firestore.collection('games').doc(this.props.roomCode).onSnapshot(
      this.updateGameData
    );
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="lobby-page">
          <ScreenLoader />
        </div>
      );
    }

    let content;

    if (this.state.game.inProgress) {
      content = (
        <InProgress
          firebase={ this.props.firebase }
          user={ this.props.user }
          game={ this.state.game }
          cards={ this.state.game.hands[this.props.user] }
          discard={ this.state.game.discard }
        />
      );
    } else {
      content = (
        <Waiting
          roomCode={ this.props.roomCode }
          players={ this.state.game.players }
          playerNames={ this.state.game.playerNames }
          startGame={ this.startGame }
          leaveGame={ this.props.leaveGame }
          showStartButton={ this.state.game.host === this.props.user }
        />
      );
    }

    return (
      <div className="lobby-page">
        { content }
      </div>
    );
  }
}

export default LobbyPage;
