import React from 'react';
import './styles/index.scss';

import AppHeader from './components/AppHeader';
import LoginPopup from './components/LoginPopup';
import ScreenLoader from './components/ScreenLoader';

import HomePage from './components/HomePage';
import LobbyPage from './components/LobbyPage';
import ProfilePage from './components/ProfilePage';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'home',
      user: null,
      roomCode: '',
      isLoading: false,
      showPopup: false
    };

    this.createGame = this.createGame.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.leaveGame = this.leaveGame.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged(async (user) => {
      if (user && user.emailVerified) {
        try {
          this.setState({
            isLoading: true,
            user
          });

          const userSnapshot = await this.props.firebase.getCurrentGame(user.uid);
          const userData = userSnapshot.data();
          if (userData && userData.roomCode && userData.roomCode !== '0000') {
            this.setState({
              page: 'lobby',
              roomCode: userData.roomCode
            })
          }
        } finally {
          this.setState({ isLoading: false });
        }
      } else if (user && !user.emailVerified) {
        window.alert('please verify your email!');
        this.setState({ user: null });
      } else {
        this.setState({ user: null });
      }
    });
  }

  async logout() {
    this.setState({ isLoading: true });
    await this.props.firebase.logout();
    this.setState({
      isLoading: false,
      page: 'home'
    });
  }

  async joinGame(roomCode, name) {
    if (!this.state.user) {
      this.togglePopup();
      return;
    }

    if (roomCode.length !== 4) {
      window.alert('Room code should be 4 characters');
      return;
    }
    try {
      this.setState({ isLoading: true });
      const response = await this.props.firebase.joinGame(roomCode.toUpperCase(), name);

      this.setState({
        page: 'lobby',
        roomCode: response.data.roomCode
      });
    } catch (error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async createGame(name) {
    if (!this.state.user) {
      this.togglePopup();
      return;
    }

    try {
      this.setState({ isLoading: true });
      const response = await this.props.firebase.createGame(name);

      this.setState({
        page: 'lobby',
        roomCode: response.data.roomCode
      });
    } catch (error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async leaveGame() {
    try {
      this.setState({ isLoading: true });
      await this.props.firebase.leaveGame();

      this.setState({
        page: 'home',
        roomCode: ''
      });
    } catch (error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  togglePopup = () => {
    //TODO: Check if signed in
    this.setState({ showPopup: !this.state.showPopup });
  }

  render() {
    let page = (
      <HomePage
        createGame={ this.createGame }
        joinGame={ this.joinGame }
      />
    );

    if (this.state.page === 'profile') {
      page = <ProfilePage logout={ this.logout } />;
    } else if (this.state.page === 'lobby') {
      page = (
        <LobbyPage
          firebase={ this.props.firebase }
          user={ this.state.user.uid }
          leaveGame={ this.leaveGame }
          roomCode={ this.state.roomCode }
        />
      );
    }

    const popup = <LoginPopup
      firebase={ this.props.firebase }
      togglePopup={ this.togglePopup }
    />;

    return (
      <div className="app">
        { this.state.isLoading && <ScreenLoader />}
        { this.state.showPopup && popup }
        {
          this.state.page !== 'lobby' &&
          <AppHeader
            signedIn={ Boolean(this.state.user) }
            togglePopup={ this.togglePopup }
            openPage={ (page) => this.setState({ page }) }
          />
        }
        { page }
      </div>
    );
  }
}

export default App;
