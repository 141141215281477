import React from 'react';

const AppFooter = () => (
  <div className="app-footer">
    <a
      className="footer-link"
      href="https://kchopp.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      { 'Made with ❤️ by Kendel Chopp' }
    </a>
  </div>
);

export default AppFooter;
