import React from 'react';

const ScreenLoader = () => (
  <div className="screen-loader">
    <div className="loader">
      <div className="bubble1"></div>
      <div className="bubble2"></div>
      <div className="bubble3"></div>
    </div>
  </div>
);

export default ScreenLoader;
