import classNames from 'classnames';
import React from 'react';

import AppFooter from './AppFooter';
import Button from './Button';
import Input from './Input';

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roomCode: '',
      name: '',
      joinTab: false
    };
  }

  updateRoomCode = (inputRoomCode) => {
    const roomCode = inputRoomCode.toUpperCase();
    if (/^[A-Z]+$/.test(roomCode) || !roomCode) {
      this.setState({ roomCode });
    }
  }

  render() {
    const joinButton = this.state.joinTab && (
      <Button
        onClick={ () => this.props.joinGame(this.state.roomCode, this.state.name) }
      >
        { 'Join Game' }
      </Button>
    );

    const createButton = !this.state.joinTab && (
      <Button onClick={ () => this.props.createGame(this.state.name) }>{ 'Create Game' }</Button>
    );

    const roomInput = this.state.joinTab && (
      <Input
        label="Room Code*"
        id="room-code"
        value={ this.state.roomCode }
        onChange={ (roomCode) => this.updateRoomCode(roomCode) }
        maxLength={ 4 }
        onDark
      />
    );

    const nameInput = (
      <Input
        label="Name"
        id="name"
        value={ this.state.name }
        onChange={ (name) => this.setState({ name }) }
        onDark
      />
    )

    return (
      <div className="home-page">
        <h1 className="title">{ 'Rummy' }</h1>
        <div className="tab-card">
          <div className="tab-selector">
            <span
              onClick={ () => this.setState({ joinTab: false }) }
              className={ classNames('tab-button', { selected: !this.state.joinTab }) }
            >
              { 'Create Game' }
            </span>
            <span
              onClick={ () => this.setState({ joinTab: true }) }
              className={ classNames('tab-button', { selected: this.state.joinTab }) }
            >
              { 'Join Game' }
            </span>
          </div>
          <div className="tab-content">
            { nameInput }
            { roomInput }
            { joinButton }
            { createButton }
          </div>
        </div>
        <AppFooter />
      </div>
    );
  }
}

export default HomePage;
