import React from 'react';

import AppFooter from './AppFooter';
import Button from './Button';

const ProfilePage = (props) => {
  return (
    <div className="profile-page">
      <h1 className="title">{ 'Profile' }</h1>
      <Button onClick={ props.logout }>{ 'Logout' }</Button>
      <AppFooter />
    </div>
  );
};

export default ProfilePage;
