import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';

import Button from '../Button';
import PlayingCard from '../PlayingCard';
import ScreenLoader from '../ScreenLoader';

import YourHand from './YourHand';

const setRequirements = [2, 1, 0, 3, 2, 1, 0];
const runRequirements = [0, 1, 2, 0, 1, 2, 3];

class InProgress extends React.Component {
  constructor(props) {
    super(props);

    // Game methods
    this.clickAction = this.clickAction.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
    this.clickHand = this.clickHand.bind(this);
    this.discard = this.discard.bind(this);
    this.drawFromDeck = this.drawFromDeck.bind(this);
    this.drawFromDiscard = this.drawFromDiscard.bind(this);
    this.goDown = this.goDown.bind(this);
    this.playCard = this.playCard.bind(this);
    this.startGoDown = this.startGoDown.bind(this);

    // Rendering methods
    this.getActionButton = this.getActionButton.bind(this);
    this.getDirections = this.getDirections.bind(this);
    this.getDrawArea = this.getDrawArea.bind(this);
    this.getEnemyHands = this.getEnemyHands.bind(this);
    this.getPiles = this.getPiles.bind(this);
    this.getYourHand = this.getYourHand.bind(this);

    this.state = {
      isGoingDown: false,
      isLoading: false,
      sortBySet: false,
      setsLeft: 0,
      runsLeft: 0,
      bunches: [],
      currentBunch: 0,
      pickedCardValue: -1,
      onHold: []
    };
  }

  get numSets() {
    return setRequirements[this.props.game.round];
  }

  get numRuns() {
    return runRequirements[this.props.game.round];
  }

  get isYourTurn() {
    return this.props.user === this.props.game.turn;
  }

  get hasGoneDown() {
    return this.props.game.goneDown[this.props.user];
  }

  async drawFromDeck() {
    try {
      this.setState({ isLoading: true });
      await this.props.firebase.drawCard(true);
    } catch(error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async drawFromDiscard() {
    try {
      this.setState({ isLoading: true });
      await this.props.firebase.drawCard(false);
    } catch(error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async discard(card) {
    try {
      this.setState({ isLoading: true });
      await this.props.firebase.discardCard(card);
      this.setState({
        pickedCardValue: -1
      });
    } catch(error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async goDown() {
    try {
      this.setState({ isLoading: true });
      await this.props.firebase.goDown(this.state.bunches);
      this.setState({
        isGoingDown: false,
        bunches: [],
        onHold: []
      });
    } catch(error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async playCard(pileIndex, cardIndex) {
    try {
      this.setState({ isLoading: true });
      await this.props.firebase.playCard(pileIndex, cardIndex, this.state.pickedCardValue);
      this.setState({ pickedCardValue: -1 });
    } catch(error) {
      window.alert(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  clickHand(cardIndex, cardValue) {
    if (this.state.isGoingDown) {
      if (this.state.bunches.length === this.state.currentBunch) {
        // start a new bunch
        this.setState({
          bunches: [...this.state.bunches, { isSet: this.state.setsLeft > 0, cards: [cardValue] }],
          onHold: [...this.state.onHold, cardIndex]
        });
      } else {
        // add to current bunch
        const bunches = this.state.bunches;
        bunches[this.state.currentBunch].cards.push(cardValue);
        this.setState({
          bunches,
          onHold: [...this.state.onHold, cardIndex]
        });
      }
    } else {
      if (this.hasGoneDown) {
        if (cardValue === this.state.pickedCardValue) {
          this.setState({ pickedCardValue: -1 });
        } else {
          this.setState({ pickedCardValue: cardValue });
        }

      } else {
        this.discard(cardValue);
      }
    }
  }

  clickCancel() {
    this.setState({
      isGoingDown: false,
      bunches: [],
      onHold: []
    });
  }

  clickAction() {
    if (this.state.isGoingDown) {
      if (this.state.setsLeft > 0) {
        // Trying to finish a set
        if (this.state.bunches.length === this.state.currentBunch) {
          window.alert('You do not have any cards in this set!');
        } else {
          if (this.state.bunches[this.state.currentBunch].cards.length < 3) {
            window.alert('You do not have 3 cards in this set!');
          } else {
            //TODO: CHECK IT IS A VALID SET
            if (this.state.setsLeft === 1 && this.state.runsLeft === 0) {
              // Done with all sets
              this.goDown();
            } else {
              // Still has stuff to do
              this.setState({
                setsLeft: this.state.setsLeft - 1,
                currentBunch: this.state.currentBunch + 1
              });
            }
          }
        }
      } else if (this.state.runsLeft > 0) {

      }
    } else {
      this.startGoDown();
    }
  }

  startGoDown() {
    if (this.isYourTurn && this.props.game.hasDrawn) {
      this.setState({
        isGoingDown: true,
        currentBunch: 0,
        setsLeft: this.numSets,
        runsLeft: this.numRuns,
        onHold: []
      });
    } else {
      window.alert('It must be your turn and you must draw');
    }
  }

  getActionButton() {
    let text = 'Go Down';

    if (this.state.isGoingDown) {
      if (this.state.setsLeft > 0) {
        text = 'Finish Set';
      } else if (this.state.runsLeft > 0) {
        text = 'Finish Run';
      }
    }

    const cancelButton = this.state.isGoingDown && (
      <Button onClick={ this.clickCancel }>{ 'Cancel' }</Button>
    );

    return (
      <div className="action-buttons">
        <Button
          onClick={ () => this.setState({ sortBySet: true }) }
          disabled={ this.state.sortBySet }
        >
          {'Sort by Number'}
        </Button>
        <Button
          className="action-button"
          onClick={ this.clickAction }
          disabled={ !this.isYourTurn || !this.props.game.hasDrawn || this.hasGoneDown }
        >
          { text }
        </Button>
        { cancelButton }
        <Button
          onClick={ () => this.setState({ sortBySet: false }) }
          disabled={ !this.state.sortBySet }
        >
          { 'Sort by Suit' }
        </Button>
      </div>
    );
  }

  getDirections() {
    let directions = 'It is not your turn.';

    if (this.isYourTurn) {
      if (this.state.isGoingDown) {
        if (this.state.setsLeft > 0) {
          directions = 'Pick cards for a set';
        } else if (this.state.runsLeft > 0) {
          directions = 'Pick cards for a run';
        }
      } else if (this.props.game.hasDrawn) {
        directions = 'Please discard or go down';
      } else {
        directions = 'Please draw';
      }
    }

    return <h3 className="directions">{ directions }</h3>
  }

  getDrawArea() {
    const isDiscarding = this.isYourTurn && this.state.pickedCardValue !== -1 && this.props.cards.length > 1;
    const highlightDeck = this.isYourTurn && !this.props.game.hasDrawn;

    let onClick;
    if (highlightDeck) {
      onClick = this.drawFromDiscard;
    } else if (isDiscarding) {
      onClick = ( () => this.discard(this.state.pickedCardValue));
    }

    return (
      <div className="draw-area">
        <PlayingCard
          onClick={ onClick }
          highlighted={ highlightDeck || isDiscarding }
          value={ _.last(this.props.discard) }
        />
        <PlayingCard
          onClick={ highlightDeck && this.drawFromDeck }
          highlighted={ highlightDeck }
          isDeck
        />
      </div>
    );
  }

  getEnemyHands() {
    const hands = _.map(this.props.game.players, (player, index) => {
      if (player === this.props.user) {
        return null;
      }

      const numCards = this.props.game.hands[player].length;

      const cards = _.times(numCards, (index) => (
        <PlayingCard key={ index } isDeck />
      ));

      const turn = this.props.game.turn === player

      const playerClasses = classNames('player-name', { turn });

      return (
        <div key={ index } className="enemy-hand">
          <h2 className={ playerClasses }>{ `${this.props.game.playerNames[player]} (${numCards})${turn ? '*' : ''}` }</h2>
          <div className="cards">
            { cards }
          </div>
        </div>
      );
    });

    return (
      <div className="enemy-hands">
        { hands }
      </div>
    );
  }

  getPiles() {
    const piles = _.map(this.props.game.piles, (pile, pileIndex) => {
      const cards = _.map(pile.cards, (card, cardIndex) => {
        let highlighted = this.state.pickedCardValue === 53;

        if (this.state.pickedCardValue !== -1 && !highlighted) {
          if (card === 53) {
            if (pile.isSet) {
              let setValue;
              let hasFoundValue = false;
              let pileCardIndex = 0;
              while (!hasFoundValue && pileCardIndex < pile.cards.length) {
                if (pile.cards[pileCardIndex] !== 53) {
                  setValue = pile.cards[pileCardIndex] % 13;
                  hasFoundValue = true;
                }
                pileCardIndex++;
              }
              highlighted = this.state.pickedCardValue % 13 === setValue;
            } else {
              //TODO: HANDLE RUNS
            }
          } else {
            highlighted = pile.isSet ?
              this.state.pickedCardValue % 13 === card % 13 :
              false; //TODO: actually check runs
          }
        }

        return (
          <PlayingCard
            value={ card }
            key={ cardIndex }
            highlighted={ highlighted }
            onClick={ highlighted && (() => this.playCard(pileIndex, cardIndex)) }
          />
        )
      });

      return (
        <div className="pile" key={ pileIndex }>
          { cards }
        </div>
      );
    });

    const bunches = _.map(this.state.bunches, (bunch, index) => {
      const cards = _.map(bunch.cards, (card, index) => (
        <PlayingCard highlighted value={ card } key={ index } />
      ));

      return (
        <div className="pile" key={ index }>
          { cards }
        </div>
      );
    });

    return (
      <div className="piles">
        { piles }
        { bunches }
      </div>
    )
  }

  getYourHand() {
    return (
      <YourHand
        hasDrawn={ this.props.game.hasDrawn }
        isYourTurn={ this.isYourTurn }
        cards={ this.props.cards }
        onHold={ this.state.onHold }
        pickedCardValue={ this.state.pickedCardValue }
        clickHand={ this.clickHand }
        sortBySet={ this.state.sortBySet }
      />
    );
  }

  render() {
    return (
      <div className="in-progress">
        { this.state.isLoading && <ScreenLoader /> }
        { this.getEnemyHands() }
        { this.getPiles() }
        <div className="fixed">
          { this.getDrawArea() }
          { this.getDirections() }
          { this.getActionButton() }
          { this.getYourHand() }
        </div>
      </div>
    );
  }
};

export default InProgress;
