import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const SUIT_EMOJIS = {
  clubs: '♣',
  diamonds: '♦',
  hearts: '❤',
  spades: '♠',
  joker: '🤡'
};

const SUITS = ['clubs', 'diamonds', 'hearts', 'spades', 'joker'];
const NUMBERS = ['K', 'A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q'];

const PlayingCard = (props) => {
  if (props.isDeck) {
    const deckClasses = classNames('deck', {
      clickable: props.onClick,
      highlighted: props.highlighted
    });
    return (
      <div
        className={ deckClasses }
        onClick={ props.onClick ? (() => props.onClick()): undefined }
      />
    );
  }

  const suit = SUITS[Math.floor((props.value - 1) / 13)];
  let number = suit === 'joker' ? '🤡' : NUMBERS[props.value % 13];

  const cardClasses = classNames(
    'playing-card',
    suit,
    {
      highlighted: props.highlighted,
      clickable: props.onClick,
      zoomed: props.zoomed
    }
  );

  return (
    <div
      onClick={ props.onClick ? (() => props.onClick()) : undefined }
      className={ cardClasses }
    >
      <p className="number">{ number }</p>
      <p className="suit">{ SUIT_EMOJIS[suit] }</p>
    </div>
  );
};

PlayingCard.propTypes = {
  value: PropTypes.number,
  highlighted: PropTypes.bool,
  isDeck: PropTypes.bool,
  onClick: PropTypes.func,
  zoomed: PropTypes.bool
};

export default PlayingCard;
