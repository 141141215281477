import React from 'react';

const AppHeader = (props) => {

  const actionButton = props.signedIn ? 'Profile' : 'Login'; //TODO: MAKE BUTTON

  const action = props.signedIn ? () => props.openPage('profile') : props.togglePopup;

  return (
    <div className="app-header">
      <h2 onClick={ () => props.openPage('home') } className="title">{ 'Rummy' }</h2>
      <h2 onClick={ action } className="action-button">{ actionButton }</h2>
    </div>
  )
};

export default AppHeader;
