import classNames from 'classnames';
import React from 'react';

import Button from './Button';
import Input from './Input';
import ScreenLoader from './ScreenLoader';

class LoginPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginTab: true,
      isLoading: false,
      email: '',
      password: '',
      passwordConfirmation: ''
    };

    this.submitForm = this.submitForm.bind(this);
  }

  switchContext = () => this.setState({
    loginTab: !this.state.loginTab,
    email: '',
    password: '',
    passwordConfirmation: ''
  });

  async submitForm() {
    if (this.state.loginTab) {
      if (!this.state.email || !this.state.password) {
        window.alert('You are missing something!');
      } else {
        this.setState({ isLoading: true });
        try {
          await this.props.firebase.signIn(this.state.email, this.state.password);
          this.props.togglePopup();
        } catch (error) {
          window.alert(error);
          this.setState({ isLoading: false });
        } finally {
        }
      }
    } else {
      if (
        !this.state.email ||
        !this.state.password ||
        this.state.password !== this.state.passwordConfirmation
      ) {
        window.alert('You are missing something!');
      } else {
        this.setState({ isLoading: true });
        try {
          const response = await this.props.firebase.signUp(this.state.email, this.state.password);
          await response.user.sendEmailVerification();
          window.alert('Signed up! Check your email!');
          this.props.togglePopup();
        } catch (error) {
          window.alert(error);
          this.setState({ isLoading: false });
        }
      }
    }
  };

  render() {
    const text = this.state.loginTab ? 'Login' : 'Sign Up';

    const passwordConfirmation = !this.state.loginTab && (
      <Input
        type="password"
        label="Password Confirmation*"
        value={ this.state.passwordConfirmation }
        onChange={ (passwordConfirmation) => this.setState({ passwordConfirmation }) }
      />
    );

    return (
      <div className="login-popup">
        { this.state.isLoading && <ScreenLoader /> }
        <div className="content">
          <span className="x" onClick={ this.props.togglePopup }>{ 'X' }</span>
          <div className="tab-selector">
            <span
              onClick={ () => this.setState({ loginTab: true }) }
              className={ classNames('tab-button', { selected: this.state.loginTab }) }
            >
              { 'Login' }
            </span>
            <span
              onClick={ () => this.setState({ loginTab: false }) }
              className={ classNames('tab-button', { selected: !this.state.loginTab }) }
            >
              { 'Sign Up' }
            </span>
          </div>
          <Input
            type="email"
            label="Email Address*"
            value={ this.state.email }
            onChange={ (email) => this.setState({ email }) }
          />
          <Input
            type="password"
            label="Password*"
            value={ this.state.password }
            onChange={ (password) => this.setState({ password }) }
          />
          { passwordConfirmation }
          <Button onClick={ this.submitForm }>{ text }</Button>
        </div>
      </div>
    )
  }
}

export default LoginPopup;
